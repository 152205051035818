import Link from 'next/link';
import Image from 'next/image';

const CardDate = ({ imageCard = "editar", nameCard = "Tarjeta", date = [], isSuperAdmin, accessMenus, user, button1 = [], button2 = [], accessButton1, accessButton2 }) => {
  
  const verifiedMenu = (name, id) => {
    const findMenu = accessMenus.find((item) => item.name === name);
    if (findMenu && findMenu.access && findMenu.access.includes(id.toString())) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
      <div className="card" style={{ height: 350, background: 'none' }}>
        <div className="d-flex justify-content-center align-items-center" style={{ height: 130, background: 'black' }}>
          <h2 className="text-center text-white pe-3">{nameCard}</h2>
          <div>
            <Image src={`/statics/img/icon_menu/${imageCard}.png`} width={50} height={50} alt="..." style={{ filter: 'invert(1)'}} />
          </div>
        </div>
        <div className="card-body mt-5">
          <div className="card-text">
            <table className="table">
              <tbody className='h5'>
                {date[0] && 
                  <tr>
                    <th>{date[0] ? date[0][0] : ""}</th>
                    <td style={{ textAlign: 'right'}}>
                      <b>{date[0] ? date[0][1] : ""}</b>
                    </td>
                  </tr>
                }
                {date[1] &&
                  <tr>
                    <th>{date[1] ? date[1][0] : ""}</th>
                    <td style={{ textAlign: 'right'}}>
                      <b>{date[1] ? date[1][1] : ""}</b>
                    </td>
                  </tr>
                }
                {date[2] &&
                  <tr>
                    <th>{date[2] ? date[2][0] : ""}</th>
                    <td style={{ textAlign: 'right'}}>
                      <b>{date[2] ? date[2][1] : ""}</b>
                    </td>
                  </tr>
                }
                {date[3] && 
                  <tr>
                    <th>{date[3] ? date[3][0] : ""}</th>
                    <td style={{ textAlign: 'right'}}>
                      <b>{date[3] ? date[3][1] : ""}</b>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <div style={{ position: 'absolute', bottom: 10 }}>
            {(isSuperAdmin || (user && verifiedMenu(accessButton1, user.id))) && (
              button1[0] == "view" && 
                <Link href={button1[1] ? `/admin/${button1[1]}` : '/'}>
                  <button type="button" className="btn btn-secondary btn-lg">
                    {button1[2] ? button1[2] : ""}
                  </button>
                </Link>
            )}
            &nbsp;
            {(isSuperAdmin || (user && verifiedMenu(accessButton2, user.id))) && (
              button2[0] == "view" && 
                <Link href={button2[1] ? `/admin/${button2[1]}` : '/'}>
                  <button type="button" className="btn btn-dark btn-lg">
                  {button2[2] ? button2[2] : ""}
                  </button>
                </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDate;
