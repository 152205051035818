import React, { useState, useEffect } from "react";
import { BASE_URL } from "../request/settings";

const useDatesAdmin = () => {
  const [dates, setDates] = useState([])

  useEffect(() => {
    const getDates = async () => {
      const url = `${BASE_URL}/dates_admin`
      const response = await fetch(url)
      const data = await response.json()
      setDates(data);
    }
    getDates()
  }, [])

  return dates;
}

export default useDatesAdmin;