import Layout from '../components/layouts/admin/Layout';
import Principal from '../components/admin/Principal';
import useDatesAdmin from '../hooks/useDatesAdmin';
import { useUser } from "@context/userContext";
import { useMenuAccess } from "@context/MenuContext";

export default function Admin() {
  const { user } = useUser();
  const { accessMenus } = useMenuAccess();
  const datesIndex = useDatesAdmin();

  return (
    <Layout>
      <Principal user={user} datesIndex={datesIndex} accessMenus={accessMenus}/>
    </Layout>
  );
}
